<template>
  <div>
    <!-- 手机端 -->
    <div class="phone">
      <div class="boxbg d-flex" v-for="(item, index) in gunarr" :key="index">
        <img class="boximg" :src="item.box.img" alt />
        <div class="boxname">
          <p class="boxname-p">
            {{ item.activity.boxName
            }}
            <span>(充值金额{{ item.activity.rechargeMin }})</span>
          </p>
          <p class="receive" @click="openbox(item.activity.id)">{{item.statusColumn}}</p>
          <!-- <p class="receive" @click="openbox()">可领取</p> -->
        </div>
      </div>
    </div>
    <!-- 电脑端 -->
    <div></div>
  </div>
</template>

<script>
import { getwelfare,opentwelfareAPI } from "../../../api/index";
export default {
  data() {
    return {
      gunarr: []
    };
  },
  created() {
    getwelfare(4).then(res => {
      this.gunarr = res.data.data;
      
    });
  },
  methods:{
    openbox(id){
      opentwelfareAPI(id).then(res=>{
        // console.log(res);
       if (res.data.code == 200) {
          this.$message.success(`领取成功！`);
        } else {
          this.$message.error(res.data.msg);
        }
      })
    }
  }
  
};
</script>


<style scoped lang="scss">
.phone {
  padding: 0 10px;
  display: flex;
  .boxbg {
    width: 98%;
    height: 165px;
    background: url("~@/assets/images/Welfare/rw.png") no-repeat;
    background-size: 100% 100%;
    align-items: center;
    justify-content: space-evenly;

    .boximg {
      width: 80px;
      height: 70px;
      display: inline-block;
      margin: 0 0 24px -7px;
    }
    .boxname {
      .boxname-p {
        font-size: 17px;
        color: white;
        display: inline-block;
        margin: 15px 0;
        span {
          font-size: 12px;
          color: yellow;
        }
      }
      .receive {
        color: white;
      }
    }
  }
}
@media (max-width: 550px) {
  .phone {
    padding: 0 10px;
    display: block;
    .boxbg {
      width: 98%;
      height: 165px;
      background: url("~@/assets/images/Welfare/rw.png") no-repeat;
      background-size: 100% 100%;
      align-items: center;
      justify-content: space-evenly;
      .boximg {
        width: 69px;
        height: 48px;
        display: inline-block;
      }
      .boxname {
        .boxname-p {
          font-size: 17px;
          color: white;
          display: inline-block;
          margin: 15px 0;
          span {
            font-size: 12px;
            color: yellow;
          }
        }
        .receive {
          color: white;
        }
      }
    }
  }
}
</style>